/* .container {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    overflow: hidden;

} */

.deck {
    position: absolute;
    width: 300px;
    height: 200px;
    will-change: transform;
    display: flex;
    align-items: center;
    justify-content: center;
    touch-action: none;
}

.deck>div {
    background-size: auto 85%;
    background-repeat: no-repeat;
    background-position: center center;
    width: 45vh;
    max-width: 150px;
    height: 85vh;
    max-height: 285px;
    will-change: transform;
    border-radius: 10px;
}