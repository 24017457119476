

/* blog list scroll bar  */
.blog_list {
    scrollbar-width: thin;
    scrollbar-color: #A5A5A5;
  }
  
  /* Chrome, Edge, and Safari */
  .blog_list::-webkit-scrollbar {
    width: 5px;
  }
  
 .blog_list::-webkit-scrollbar-track {
    background: #A5A5A5;
    border-radius: 2px;
  }
  
 .blog_list::-webkit-scrollbar-thumb {
    background-color: #F79F15;
    border-radius: 14px;
    border: 2px solid #F79F15 ;
}
  
